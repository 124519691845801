import React from 'react'
import './Standard.css'
import './States.css'
import logo from '../../img/AZVRC2024_Full_White_Logo.png'

function VolunteerStates() {
  return (
    <div className='standard-box'>
      <div className='states-header'>
      <img src={logo} className='states-logo'></img>
      </div>
    <div className='standard-container'>
      <center>
        <h1>Volunteer at States!</h1>
        <div className='row'>
        <div className='row-item'>
        <h2>Middle School</h2>
          <p>March 1, 2025</p>
          <p>Desert Ridge High School, Mesa</p>
          <a href="https://forms.gle/1H1NhuduX1rUxxsW8" target="_blank" className='link'>
          <button value="SIGN UP" className='ms-button'>Volunteer</button>
          </a>
          </div>
        <div className='row-item'>
        <h2>High School</h2>
          <p>March 7-8, 2025</p>
          <p>Desert Ridge High School, Mesa</p>
          <a href="https://forms.gle/aVZAQti2Fr8n44w9A" target="_blank" className='link'>
          <button value="SIGN UP" className='hs-button'>Volunteer</button>
          </a>
          </div>
          </div>
          </center>
    </div>
    </div>
  )
}

export default VolunteerStates