import React from 'react'
import './Standard.css'

function Teams() {
  return (
    <div className='standard-box'>
      <div className='teams-header'>
      <p>TEAMS</p>
      </div>
    <div className='standard-container'>
      <h2>Livestream and Event Details</h2>
      <p>Our events are livestreamed on our <a href="https://yt.18x18az.org">YouTube channel</a>.
          Other event details can be found at <a href="http://re.18x18az.org">re.18x18az.org</a>.
      </p>
      <h2>Photo Albums</h2>
      <p><a href="https://photos.app.goo.gl/S5Ubz76o3YLhZkFn9" target="_blank">2024 VRC High School State Championship</a></p>
      <p><a href="https://photos.app.goo.gl/bHQstAs7DED4AKRD8" target="_blank">2023 VRC High School State Championship</a></p>
      <p><a href="https://photos.app.goo.gl/p4k6PUTUACBN4jR49" target="_blank">2023 VRC Middle School State Championship</a></p>
      <p><a href="https://photos.app.goo.gl/W1kGGQWcVCRfpp1d9" target="_blank">2022 VRC High School State Championship</a></p>
      
      <h2>2022 Arizona VRC Team Workshop</h2>
      <p><a href="https://drive.google.com/drive/folders/1u0ZX6ANYNAU3UvK6L40sPIhcFMjKq5OH" target="_blank">Presentation Slides</a></p>
      <p><a href="https://youtu.be/IJ6GKBwPJgE" target="_blank">Main Room VOD</a></p>
      <ul>
        <li>Robot Design and Team Structure</li>
        <li>Judging and Engineering Notebooks</li>
        <li>Robot Building Tips</li>
        <li>Competition Best Practices</li>
      </ul>
      <p><a href="https://youtu.be/5nccZWn8Kas" target="_blank">Second Room VOD</a></p>
      <ul>
        <li>Sensors and Control</li>
        <li>Intro to Programming</li>
      </ul>
    </div>
    </div>
  )
}

export default Teams
