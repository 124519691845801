import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import logo from '../logo.svg'
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img src={logo}  className='hero-logo'></img>
      <p>Serving the Arizona VEX Community</p>

      <h3 className='subtext' >
      We specialize in supporting and facilitating high-quality VEX Robotics Competition events in
      Arizona, where students apply the engineering design process in an engaging and competitive atmosphere.</h3>
    </div>
    
  );
}

export default HeroSection;